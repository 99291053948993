const TitleBar: React.FC = () => {
  return (
    <>
      <div className="titlebar">
        <div className="buttons">
          <div className="close"></div>
          <div className="minimize"></div>
          <div className="zoom"></div>
        </div>
        <span className="title">Slack</span>
      </div>
      <style jsx>{`
        .titlebar {
          background: -webkit-linear-gradient(top, #ebebeb, #d5d5d5);
          background: -moz-linear-gradient(top, #ebebeb, #d5d5d5);
          background: -ms-linear-gradient(top, #ebebeb, #d5d5d5);
          background: -o-linear-gradient(top, #ebebeb, #d5d5d5);
          background: linear-gradient(top, #ebebeb, #d5d5d5);
          background-color: #ebebeb;
          color: #4d494d;
          font-size: 11pt;
          line-height: 17px;
          text-align: center;
          width: 100%;
          height: 20px;
          border-top: 1px solid #f3f1f3;
          border-bottom: 1px solid #b1aeb1;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          user-select: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          -o-user-select: none;
          cursor: default;
        }

        .title {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-right: 2.25rem;
        }

        .buttons {
          padding-left: 8px;
          padding-top: 3px;
          float: left;
          line-height: 0px;
        }

        .close {
          background: #ff5c5c;
          font-size: 9pt;
          width: 11px;
          height: 11px;
          border: 1px solid #e33e41;
          border-radius: 50%;
          display: inline-block;
        }

        .minimize {
          background: #ffbd4c;
          font-size: 9pt;
          line-height: 11px;
          margin-left: 4px;
          width: 11px;
          height: 11px;
          border: 1px solid #e09e3e;
          border-radius: 50%;
          display: inline-block;
        }

        .zoom {
          background: #00ca56;
          font-size: 9pt;
          line-height: 11px;
          margin-left: 6px;
          width: 11px;
          height: 11px;
          border: 1px solid #14ae46;
          border-radius: 50%;
          display: inline-block;
        }
      `}</style>
    </>
  );
};

export default TitleBar;
