import LockBot, { Response } from "./lock-bot";
import ImMemoryLockRepo from "./storage/in-memory-lock-repo";
import { MessageContent } from "../../components/message";

const getMessage = (response: Response): MessageContent => {
  return {
    author: "Lockbot",
    text: response.message
      .split(">")
      .join("\n>")
      .split("*")
      .join("**")
      .replace("You has", "You have"),
    dateIsoString: new Date().toISOString(),
    visibility: response.destination,
  };
};

const lockBot = new LockBot(new ImMemoryLockRepo());
const askLockbot = async (
  input: string,
  user: string
): Promise<MessageContent | null> => {
  const tokens = input.split(" ");
  const command = tokens[0];
  const resource = tokens[1];
  if (command === "/locks") {
    return getMessage(await lockBot.locks("channel", "team"));
  }
  if (command === "/unlock") {
    return getMessage(await lockBot.unlock(resource, user, "channel", "team"));
  }
  if (command === "/lock") {
    return getMessage(await lockBot.lock(resource, user, "channel", "team"));
  }
  return null;
};

export default askLockbot;
