import React, { useState, useEffect, useCallback, useRef } from "react";
import Messages from "./messages";
import { MessageContent, MessageContext } from "./message";
import Input, { InputContext } from "./input";
import askLockbot from "../lib/lockbot/wrapper";
import { gtagEvent } from "../lib/gtag";
import TitleBar from "./title-bar";
import Confetti from "react-dom-confetti";

const Chat: React.FC = () => {
  const initialMessages: MessageContent[] = [
    {
      author: "You",
      text:
        "Heyyy 👋 is anyone using the `test-environment` right now? I'd like to use it for a bit please",
      dateIsoString: new Date(Date.now() - 1734 * 60).toISOString(),
      visibility: "channel",
    },
    {
      author: "Your teammate",
      text:
        "Good question! You can ask our friend **Lockbot** to show a list of things currently locked by the team by using the `/locks` command 🙌\n" +
        "> PS. Feel free to try the other commands like `/lock [resource-name]` and `/unlock [resource-name]`",
      dateIsoString: new Date().toISOString(),
      visibility: "channel",
    },
  ];
  const [messages, setMessages] = useState<MessageContent[]>(initialMessages);
  const [typingTriggered, setTypingTriggered] = useState(false);
  const [floaterOpen, setFloaterOpen] = useState(false);
  const [lockbotHasResponded, setLockbotHasResponded] = useState(false);
  const [userHasSentMessage, setUserHasSentMessage] = useState(false);
  const lockbotHasRespondedRef = useRef(lockbotHasResponded);
  lockbotHasRespondedRef.current = lockbotHasResponded;
  const setupLocks = useCallback(async () => {
    await askLockbot("/lock balcony-key", "Janet");
    await askLockbot("/lock test-environment", "Thomas");
  }, []);
  useEffect(() => {
    if (typingTriggered) {
      setupLocks();
      const floaterOpenTimer = setTimeout(() => {
        if (!lockbotHasRespondedRef.current) {
          setFloaterOpen(true);
        }
      }, 3000);
      return () => {
        clearTimeout(floaterOpenTimer);
      };
    }
  }, [typingTriggered]);

  const onSendMessage = (message: string) => {
    askLockbot(message, "You").then((lockbotResponse) => {
      if (lockbotResponse) {
        setMessages([...messages, lockbotResponse]);
        setLockbotHasResponded(true);
        setFloaterOpen(false);
      } else {
        setUserHasSentMessage(true);
        setMessages([
          ...messages,
          {
            author: "You",
            text: message,
            dateIsoString: new Date().toISOString(),
            visibility: "channel",
          },
        ]);
      }
    });
    gtagEvent("sent_message");
  };

  return (
    <div className="chat">
      <TitleBar />
      <div className="app">
        <div className="side-bar"></div>
        <div className="messages">
          <MessageContext.Provider value={{ userHasSentMessage }}>
            <Messages messages={messages} />
          </MessageContext.Provider>
        </div>
        <div className="input">
          <div className="confetti">
            <Confetti
              active={lockbotHasResponded}
              config={{
                spread: 20,
                startVelocity: 75,
                elementCount: 75,
                colors: ["#36C5F0", "#2EB67D", "#E01E5A", "#F9CC3D", "#BE62F4"],
              }}
            />
          </div>
          <InputContext.Provider
            value={{ typingTriggered, setTypingTriggered, floaterOpen }}
          >
            <Input onSendMessage={onSendMessage} />
          </InputContext.Provider>
        </div>
      </div>
      <style jsx>{`
        .chat {
          border: 1px solid #acacac;
          border-radius: 6px;
          box-shadow: 0px 0px 20px #acacac;
          margin: 0.5rem 0.25rem 0.75rem;
        }

        .app {
          display: grid;
          grid-template-columns: 2rem 1fr;
          grid-template-rows: auto 1fr;
        }

        .side-bar {
          background-color: #3f0e40;
          grid-column: 1;
          grid-row: 1 / 3;
        }

        .messages {
          grid-column: 2;
          grid-row: 1;
        }

        .input {
          grid-column: 2;
          grid-row: 2;
        }

        .confetti {
          display: flex;
          justify-content: center;
        }
      `}</style>
    </div>
  );
};

export default Chat;
