import ReactMarkdown from "react-markdown";

const markdownRenderers: {
  [nodeType: string]: React.ElementType<any>;
} = {
  paragraph: ({ children }) => (
    <>
      <p style={{ margin: "0 10px 0 0", paddingBottom: 3 }}>{children}</p>
    </>
  ),
  blockquote: ({ children }) => (
    <>
      <blockquote>{children}</blockquote>
      <style jsx>{`
        blockquote {
          margin: 0 0 0 10px;
          padding: 0.5em 10px;
          border-left: 10px solid #ccc;
        }
      `}</style>
    </>
  ),
  emphasis: ({ children }) => (
    <>
      <em style={{ margin: 0 }}>{children}</em>
    </>
  ),
};

const Markdown: React.FC<{ source: string }> = ({ source }) => {
  return <ReactMarkdown source={source} renderers={markdownRenderers} />;
};

export default Markdown;
