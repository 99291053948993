import { useState, useEffect } from "react";

// https://joshwcomeau.com/react/the-perils-of-rehydration/#abstractions
const ClientOnly: React.FC = ({ children, ...delegated }) => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }
  return <div {...delegated}>{children}</div>;
};

export default ClientOnly;
