import React, { useState, useEffect, useContext } from "react";
import VisibilitySensor from "react-visibility-sensor";
import ClientOnly from "./client-only";
import Floater from "react-floater";

export const InputContext = React.createContext<{
  typingTriggered: boolean;
  setTypingTriggered: React.Dispatch<React.SetStateAction<boolean>>;
  floaterOpen: boolean;
}>({
  typingTriggered: false,
  setTypingTriggered: null!,
  floaterOpen: false,
});

interface InputProps {
  onSendMessage: (message: string) => void;
}

const Input: React.FC<InputProps> = ({ onSendMessage }) => {
  const [text, setText] = useState("");
  const { typingTriggered, setTypingTriggered, floaterOpen } = useContext(
    InputContext
  );

  useEffect(() => {
    if (typingTriggered) {
      let deferredActions: (NodeJS.Timeout | undefined)[];
      const startTypingTimer = setTimeout(() => {
        const finalText = "/locks";
        const delay = 250;
        deferredActions = finalText.split("").map((char, index) => {
          if (index) {
            return setTimeout(() => {
              setText(finalText.slice(0, index + 1));
            }, delay * index);
          }
        });
      }, 1000);

      return () => {
        [startTypingTimer, ...deferredActions].forEach((timeout) => {
          timeout && clearTimeout(timeout);
        });
      };
    }
  }, [typingTriggered]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const trimmedText = text.trim();
    if (trimmedText) {
      setText("");
      onSendMessage(trimmedText);
    }
  };

  return (
    <VisibilitySensor
      onChange={(isVisible) => {
        if (isVisible) {
          setTypingTriggered(true);
        }
      }}
    >
      <>
        <form onSubmit={onSubmit}>
          <input
            aria-label="Message #your-team-channel"
            onChange={onChange}
            value={text}
            type="text"
            placeholder="Message #your-team-channel"
          />
          <button aria-label="Send" disabled={!text.trim()}>
            <svg
              className={floaterOpen ? "wiggle" : undefined}
              x="0px"
              y="0px"
              width="20px"
              viewBox="0 0 540 540"
            >
              <g>
                <polygon points="0,497.25 540,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75" />
              </g>
            </svg>
          </button>
        </form>
        <ClientOnly>
          <Floater
            content={
              <span>
                💡 Try sending the <code>/locks</code> command
              </span>
            }
            offset={5}
            open={floaterOpen}
            target="button"
          />
        </ClientOnly>
        <style jsx>{`
          form {
            display: flex;
            margin: 1rem;
            border: 1px solid rgba(29, 28, 29, 0.5);
            border-radius: 4px;
            overflow: hidden;
          }

          input {
            margin-left: 5px;
            font-size: 16px;
            border: none;
            flex-grow: 1;
            outline: none;
          }

          button {
            padding: 5px 15px 3px;
            border: none;
            background-color: #148567;
            fill: #ffffff;
            cursor: pointer;
          }

          button:disabled {
            background-color: #dcdcdc;
            fill: #808080;
            cursor: default;
          }

          .wiggle {
            animation: 0.6s wiggle ease infinite;
          }
          @keyframes wiggle {
            0% {
              transform: rotate(-5deg);
            }
            50% {
              transform: rotate(5deg);
            }
            100% {
              transform: rotate(-5deg);
            }
          }
        `}</style>
      </>
    </VisibilitySensor>
  );
};

export default Input;
