import Chat from "../components/chat";
import { gtagEvent } from "../lib/gtag";
import ReactTooltip from "react-tooltip";
import ClientOnly from "../components/client-only";
import { useState, useEffect } from "react";
import Layout from "../components/layout";
import Logo from "../components/logo";

export default function Home() {
  const [slackInstallUrl, setSlackInstallUrl] = useState("#");
  useEffect(() => {
    fetch("/api/slack/install").then((res) => {
      res.text().then((text) => {
        if (res.status === 200) {
          setSlackInstallUrl(text);
        } else {
          throw new Error(`Failed to get slack install URL
          ${text}`);
        }
      })
    });
  }, []);

  return (
    <Layout>
      <Logo />

      <h1>Lockbot</h1>

      <h2>Coordinate use of your team's shared resources, in Slack 🤝</h2>

      <a
        href={slackInstallUrl}
        onClick={() => {
          gtagEvent("add_to_slack");
        }}
      >
        <img
          alt="Add to Slack"
          height="40"
          width="139"
          src="https://platform.slack-edge.com/img/add_to_slack.png"
          srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
        ></img>
      </a>

      <ClientOnly>
        <ReactTooltip id="locked" type="warning" effect="solid">
          <span>
            ⚠ Lockbot cannot physically prevent naughty or unaware users from
            using a resource whilst it's locked by someone else.
          </span>
        </ReactTooltip>
      </ClientOnly>

      <p className="description">
        Lockbot only lets each shared resource be{" "}
        <span
          data-tip
          data-for="locked"
          style={{
            borderBottom: "1px dotted",
            display: "inline-block",
            lineHeight: "1rem",
          }}
        >
          locked
        </span>{" "}
        by one person at a time - like a Mutex 🔒
      </p>

      <Chat />

      <style jsx>{`
        h1,
        h2,
        p {
          text-align: center;
        }

        h1 {
          margin: 0;
          line-height: 1.15;
          font-size: 4rem;
        }

        h2 {
          font-weight: normal;
          line-height: 1.5;
          font-size: 1.5rem;
          margin-block-start: 1rem;
          margin-block-end: 1rem;
        }

        p {
          max-width: 375px;
        }
      `}</style>
    </Layout>
  );
}
