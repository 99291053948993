import React, { useState, useEffect } from "react";
import Message, { MessageContent } from "./message";

interface MessagesProps {
  messages: MessageContent[];
}

const Messages: React.FC<MessagesProps> = ({ messages }) => {
  const [seed, setSeed] = useState("");

  useEffect(() => {
    setSeed(`${Math.random()}`);
  }, []);

  return (
    <>
      <ul className="list">
        {messages.map((m) => (
          <Message
            key={`${m.author}_${m.dateIsoString}`}
            content={m}
            seed={seed}
          />
        ))}
      </ul>

      <style jsx>{`
        .list {
          padding: 0;
          max-width: 900px;
          width: 100%;
          margin: 0.5rem auto 0;
          list-style: none;
          flex-grow: 1;
          overflow: auto;
        }
      `}</style>
    </>
  );
};

export default Messages;
