import React, { useState, useRef, useContext } from "react";
import Markdown from "./markdown";
import Floater from "react-floater";
import ClientOnly from "./client-only";

export const MessageContext = React.createContext({
  userHasSentMessage: false,
});

export interface MessageContent {
  author: "You" | "Lockbot" | "Your teammate";
  text: string;
  dateIsoString: string;
  visibility: "user" | "channel";
}

interface MessageProps {
  content: MessageContent;
  seed: string;
}

const Message: React.FC<MessageProps> = ({ content: message, seed }) => {
  const { userHasSentMessage } = useContext(MessageContext);
  const [showFloater, setShowFloater] = useState(false);
  const timeRef = useRef(null);

  const isMessageFromLockbot = message.author === "Lockbot";
  const isUserOnlyMessage = message.visibility === "user";
  const messageStyle = isUserOnlyMessage
    ? { backgroundColor: "#f8f8f8" }
    : undefined;
  const avatarImage = isMessageFromLockbot
    ? "/lockbot.svg"
    : `https://api.dicebear.com/7.x/pixel-art/svg?seed=${message.author}${seed}`;

  const date = new Date(message.dateIsoString);

  return (
    <li className="message" style={messageStyle}>
      {isUserOnlyMessage ? (
        <div className="only-you">
          <span>
            <b style={{ paddingRight: "4px" }}>👁</b> Only visible to you
          </span>
        </div>
      ) : null}
      <div className="main">
        <img
          alt={`${message.author} avatar`}
          className="avatar"
          src={avatarImage}
        />
        <div className="content">
          <div className="title">
            <span className="author">{message.author}</span>
            {isMessageFromLockbot ? <span className="app">APP</span> : null}
            <span
              className="time"
              ref={timeRef}
              onMouseEnter={() => setShowFloater(true)}
              onMouseLeave={() => setShowFloater(false)}
            >
              {date.toTimeString().slice(0, 5)}
            </span>
          </div>
          <div className="text">
            <Markdown source={message.text} />
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .message {
            padding: 5px 0;
          }

          .message:hover {
            background-color: #f8f8f8;
          }

          .only-you {
            margin-left: 34px;
            color: rgba(29, 28, 29, 0.7);
            font-size: 13px;
            margin-bottom: 2px;
            line-height: 1.46668;
            font-weight: 400;
          }

          .main {
            display: flex;
          }

          .content {
            display: inline-block;
          }

          .avatar {
            height: 36px;
            width: 36px;
            border-radius: 5px;
            display: inline-block;
            margin: 0 10px -10px;
          }

          .avatar:hover {
            cursor: pointer;
          }

          .title {
            display: block;
          }

          .author {
            display: inline-block;
            font-weight: bold;
          }

          .author:hover {
            cursor: pointer;
            text-decoration: underline;
          }

          .app {
            display: inline-block;
            margin-left: 5px;
            border-radius: 2px;
            color: rgba(29, 28, 29, 0.7);
            background-color: rgba(29, 28, 29, 0.13);
            font-size: 10px;
            padding: 1px 3px;
            line-height: 1.25;
            font-weight: 700;
            vertical-align: 0.125rem;
          }

          .time {
            display: inline-block;
            margin-left: 5px;
            color: #616061;
            font-size: 90%;
          }

          .time:hover {
            cursor: pointer;
            text-decoration: underline;
          }

          .text {
            max-width: 400px;
            margin: 0;
            display: inline-block;
          }
        `}
      </style>
      {!userHasSentMessage ? ( // TODO Fix weird time tooltip bug 🐛⌚
        <ClientOnly>
          <Floater
            content={`${date.getDate()} ${date.toLocaleString("default", {
              month: "short",
            })} at ${date.toTimeString().slice(0, 8)}`}
            offset={5}
            open={showFloater}
            target={timeRef.current}
            styles={{
              floater: {
                filter: "none",
              },
              container: {
                backgroundColor: "#000",
                borderRadius: 5,
                color: "#fff",
                fontWeight: "bold",
                filter: "none",
                minHeight: "none",
                minWidth: "none",
                padding: 10,
                textAlign: "center",
              },
              arrow: {
                color: "#000",
                length: 8,
                spread: 10,
              },
            }}
          />
        </ClientOnly>
      ) : null}
    </li>
  );
};

export default Message;
